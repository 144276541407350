import { getElementTag } from '@nrk/dh-server-libs/get-element-tag'

import levendeStillbilde from '@nrk/dh-basis-levende-stillbilde'
import { ElementIntersectionObserver } from '@nrk/dh-basis-common'
import './components/VideoLoop.module.css'

const tag = getElementTag(__APP_NAME__, __APP_VERSION__)

function safeDefine(elementName, constructor) {
  if (typeof customElements.get(elementName) === 'undefined') {
    customElements.define(elementName, constructor)
  }
}

const VIEWPORT_POSITION_ATTR = 'data-viewport-position'

class AppElement extends HTMLElement {
  name = tag
  #logStatus = {
    playAttempted: false,
    playSucceeded: false,
  }

  connectedCallback() {
    this.render()
    this.addEventListener('dh-basis-autoplay-attempted', this.#logPlayAttempted)
    this.addEventListener('dh-basis-autoplay-succeeded', this.#logPlaySucceeded)
  }

  disconnectedCallback() {
    super.disconnectedCallback?.()
    this.removeEventListener('dh-basis-autoplay-attempted', this.#logPlayAttempted)
    this.removeEventListener('dh-basis-autoplay-succeeded', this.#logPlaySucceeded)
  }

  render() {
    try {
      safeDefine('dh-lvs-basis-levende-stillbilde', levendeStillbilde)

      const videoWrapperElement = this.querySelector('.dh-video-wrapper')

      // eslint-disable-next-line no-unused-vars
      const elementObserver = new ElementIntersectionObserver(
        [videoWrapperElement],
        (intersections) => {
          const element = intersections[0]
          const elementViewportPosition = videoWrapperElement.getAttribute(VIEWPORT_POSITION_ATTR)
          if (element.isIntersecting && elementViewportPosition === 'OUT-OF-VIEWPORT') {
            videoWrapperElement.setAttribute(VIEWPORT_POSITION_ATTR, 'IN-VIEWPORT')
          }
          if (!element.isIntersecting && elementViewportPosition === 'IN-VIEWPORT') {
            videoWrapperElement.setAttribute(VIEWPORT_POSITION_ATTR, 'OUT-OF-VIEWPORT')
          }
        },
      )
    } catch (err) {
      // fail silently
      console.error(`Error while rendering ${tag}`, err)
    }
  }

  #logPlayAttempted = () => {
    if (!this.#logStatus.playAttempted) {
      this.#logStatus.playAttempted = true
    }
  }

  #logPlaySucceeded = () => {
    if (!this.#logStatus.playSucceeded) {
      this.#logStatus.playSucceeded = true
    }
  }
}

if (!customElements.get(tag)) {
  customElements.define(tag, AppElement)
}
